<script setup lang="ts">
import type { Organization } from '~~/types/organization'

const props = defineProps<{
  orgList: Organization[],
  selectedOrg: Organization,
}>()

defineEmits<{
  (e: 'select', value: string): string
}>()

const orgList = props.orgList
const selectedOrg = props.selectedOrg
</script>
<template>
  <div
    class="
      md:ml-0
      md:mt-0
      ml-5
      mt-5
    "
  >
    <div
      v-for="org in orgList"
      class="
        mb-4
        md:mb-8
      "
    >
      <input
        :id="org.id"
        name="selectOrg"
        type="radio"
        v-model="selectedOrg"
        :value="org"
        @change="$emit('select', org)"
      >
      <label
        class="
          font-semibold
          ml-1
          radio-text
        "
        :for=org.id
      >
        <span
          class="
            md:text-2xl
            pl-2
            text-lg
          "
        >
          {{ org.name }}
        </span>
      </label>
      <span
        class="
          md:inline-block
        "
      >
        <br/>
      </span>
      <span
        class="
          font-bold
          md:ml-0
          md:text-2xl
          ml-7
        "
      >
        -
      </span>
      <span
        class="
          font-semibold
          md:text-2xl
          text-gray-400
        "
      >
        {{ org.id }}
      </span>
    </div>
  </div>
</template>
<style scoped>
input[type="radio"] {
  display: none;
}

.radio-text:before {
  content: "";
  display: inline-block;
  width: 14px;
  height: 14px;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  padding: 2px;
  background-clip: content-box;
}

@media (min-width: 768px){
  .radio-text:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    padding: 4px;
    background-clip: content-box;
  }
}

input[type="radio"]:not(:checked) + .radio-text:before {
  border-color: #909090;
}

input[type="radio"]:checked + .radio-text:before {
  border-color: #FE80A3;
  background-color: #FE80A3;
}
</style>
