<script setup lang='ts'>
import { ref } from 'vue'
import { Organization } from '~~/types/organization'
import { judgeScreenWidth } from '~/libs/judgeScreenWidth'

const props = defineProps<{
  closeBtnFlg: boolean,
  orgList: Organization[],
  selectedOrg: Organization,
}>()

defineEmits<{
  (e: 'close', value: string): void
  (e: 'submit', value: Organization): void
}>()

const orgList = props.orgList
const selectedOrg = ref(props.selectedOrg)
const closeBtnFlg = props.closeBtnFlg !== undefined ? props.closeBtnFlg : true
const mobileView = judgeScreenWidth()
</script>

<template>
  <!-- モーダル本体 -->
  <common-modal
    :close-btn-flg="closeBtnFlg"
    header-text="教室を選択"
    :is-original="mobileView"
    :modal-margin-x="mobileView ? 'mx-6' : 'mx-72'"
    modal-height="h-[60vh]"
    role="manager"
    @close="$emit('close', $event)"
  >
    <!-- #Bodyテンプレート -->
    <template v-slot:body>
      <page-members-classroom-option
        :org-list="orgList"
        :selected-org="selectedOrg"
        @select="($event: Organization) => {
          selectedOrg = $event
        }"
      />
    </template>
    <!-- #controlテンプレート -->
    <template v-slot:control>
      <common-btn
        @click="$emit('submit', selectedOrg)"
        btn-type="secondary"
        class="
          md:h-12
          md:mx-0
          md:w-60
          mx-3
          md:text-lg
          w-full
        "
      >
        選択する
      </common-btn>
    </template>
  </common-modal>
</template>
